// src/document.ts
import { Node } from "@tiptap/core";
var Document = Node.create({
  name: "doc",
  topNode: true,
  content: "block+"
});

// src/index.ts
var src_default = Document;
export {
  Document,
  src_default as default
};
